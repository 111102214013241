import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import styles from "../jss/landing-webdesign.js"
import classNames from "classnames"

// core components
import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"
import Grid from "@material-ui/core/Grid"

import HeaderLinks from "../components/Header/HeaderLinks.jsx"
import Parallax from "../components/Parallax/ParallaxSEO.jsx"
import Banner from "../pages-sections/SEO/Banner.jsx"
import Info from "../pages-sections/SEO/Info.jsx"
import FAQ from "../pages-sections/SEO/FAQ.jsx"

import Intro from "../pages-sections/SEO/Intro.jsx"
import Pricing from "../pages-sections/SEO/Pricing.jsx"
import Contact from "../pages-sections/SEO/Contact.jsx"
import Marketing from "../pages-sections/SEO/Marketing.jsx"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"

import {
  title,
  container,
  whiteColor,
  main,
  mainRaised,
} from "../jss/nextjs-material-kit-pro.js"

const getImages = graphql`
  {
    file(relativePath: { eq: "seo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const useStyles = makeStyles({
  container: {
    ...container,
    zIndex: "10",
    color: "#FFFFFF",
    position: "relative",
  },

  mainRaised: {
    ...main,
    ...mainRaised,
  },
  parallax: {
    height: "70vh",
    "@media screen and (max-width:500px)": {
      height: "40vh",
    },
  },
  title: {
    ...title,
    color: whiteColor + "  !important",
    marginBottom: "25px",
    textAlign: "center",
    marginTop: "0px",

    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      fontSize: "28px",
      marginLeft: "-10px",
      marginTop: "80px",
      textAlign: "center",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      textAlign: "center",
      marginTop: "240px",
    },
  },
  container2: {
    "@media screen and (max-width:500px)": {
      padding: "0px 0px 0px 0px",
      fontSize: "28px",
    },

    "@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)": {
      marginTop: "240px",
      textAlign: "center",
    },

    "@media screen and (min-width:1100px)": {
      marginTop: "160px",
    },
  },
  container3: {
    fontSize: "18px",
    "@media screen and (min-width:1500px)": {
      marginLeft: "100px",
    },
    "@media screen and (max-width:500px)": {
      padding: "-250px 0px 0px 0px",
    },
  },
  blackOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    height: "100%",
    paddingTop: "20px",
    color: "white",
  },
})

export default function LandingPage(props) {
  const classes = useStyles()
  const { ...rest } = props
  const data = useStaticQuery(getImages)
  return (
    <div>
      <LogoJsonLd
        logo="https://storage.googleapis.com/product-image-upload/web-design-toronto.png"
        url="https://atlasagency.ca"
      />
      <GatsbySeo
        title="Digital marketing Toronto | Kelowna seo | Seo Company Toronto | Toronto seo - Atlas Agency"
        description="Digital marketing in Toronto and Kelowna. We provide ongoing SEO services including paid Ads and organic seo ranking in Toronto and Kelowna."
        canonical="https://atlasagency.ca/seo-company-toronto/"
        openGraph={{
          type: "website",
          locale: "en_IE",
          description:
            "Web design Toronto based company. We provide web design and SEO services for companies of all sizes. All of our web designs are mobile and desktop optimized making the user experience seamless and quick.",
          url: "https://atlasagency.ca",
          site_name: "Atlas Agency | Web Design Toronto",
          images: [
            {
              url:
                "https://storage.googleapis.com/product-image-upload/toronto-web-design2.png",
              width: 800,
              height: 450,
              alt: "Web Design Toronto",
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@AtlasAgencyCA",
        }}
      />
      <Header
        color="transparent"
        brand=" Atlas Agency"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 60,
          color: "dark",
        }}
        {...rest}
      />

      <div filter="dark" className={classes.parallax}>
        <div className={classes.blackOverlay}>
          <Grid container>
            <Grid
              item
              xs={10}
              sm={10}
              md={8}
              lg={12}
              className={classes.container2}
            >
              <h1 className={classes.title}>Rank Higher On Google </h1>
            </Grid>

            <Img
              fluid={data.file.childImageSharp.fluid}
              fadeIn={false}
              alt="Toronto-Web-Design"
              loading="eager"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: "-1",
              }}
            />
          </Grid>
        </div>
      </div>

      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ overflowX: "hidden" }}
      >
        <div className={classes.container}>
          <Intro />
        </div>
        <div>
          <Marketing />
        </div>
        <div>
          <Pricing />
          <Banner />
          <div className={classes.container}>
            <Info />
          </div>
          <Banner />
          <div className={classes.container}>
            <FAQ />
          </div>
          <Banner />
        </div>
        <div className={classes.container}>
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  )
}
