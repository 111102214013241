import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons

// core components
import GridContainer from "../../components/Grid/GridContainer.js"
import GridItem from "../../components/Grid/GridItem.js"
import Card from "../../components/Card/Card.js"
import CardBody from "../../components/Card/CardBody.js"
import Hidden from "@material-ui/core/Hidden"
import Grid from "@material-ui/core/Grid"

import styles from "../../jss/pricingStyle.js"

const useStyles = makeStyles(styles)

export default function ProductSection({ ...rest }) {
  const classes = useStyles()
  return (
    <div  style={{ paddingBottom: "60px" }} justify="center">
      <GridContainer justify="center" alignItems="center">
        <GridItem
          xs={12}
          sm={10}
          md={8}
          style={{ marginRight: "10px", marginLeft: "30px" }}
          lg={5}
        >
          <h2
           
            style={{ textAlign: "center" }}
          >
            SEO Starter Package{" "}
        
          </h2>

          <h5 className={classes.cardDescriptionWhite}>
            Our SEO starter package will enhance your website and will help you
            begin ranking on Google's search engine. This one time fee will aid
            in the pursuit of gathering new leads and generating real profit.
            Take advantage of our experience and expertise to truly start your
            website off on the right foot. A lot of great looking websites fail
            and never see the light of day on Google without the necessary
            optimizations.
          </h5>
        </GridItem>
      </GridContainer>
      <Grid container justify="center"  style={{ alignText:"center" , paddingTop: "15px"}}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          className={classes.cardDescriptionWhite}
          lg={3}
        >
          <ul>
            <li>Keyword Research</li>
            <li>Install Google Analytics</li>
            <li>Create XML Sitemap</li>
            <li>Add ALT Tags</li>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          className={classes.cardDescriptionWhite}
          lg={4}
        >
          <ul>
            <li>Create Page Descriptions for Google Search</li>
            <li>Create Robot.txt file</li>
            <li>Add Business to Local Directories</li>
            <li>Optimize Page URL’s</li>
          </ul>
        </Grid>
      </Grid>
    </div>
  )
}
