import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"

import styles from "../../jss/infoWeb.js"

import { Link } from "gatsby"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
  link: {
    fontSize: "20px",
    color: "#273746",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" style={{ padding: "30px 0px 30px 0px" }}>
        <Grid item xs={11} sm={11} md={8} lg={8}>
          <h2
            className={classes.title}
            style={{
              color: "#273746",
            }}
          >
            WHY CHOOSE US ?
          </h2>
          <p className={classes.description} style={{}}>
            Quality web design is a necessity for improving your online
            presence. We help our customers decide on the right look for their
            website while providing innovative and effective solutions.
          </p>
        </Grid>
        <Grid item xs={11} sm={11} md={8} lg={8}>
          <h3
            className={classes.title}
            style={{
              color: "#273746",
              fontSize: "25px",
            }}
          >
            <strong> YOU MIGHT ALSO BE INTERESTED IN... </strong>
          </h3>
        </Grid>
        <Grid
          item
          xs={11}
          sm={11}
          md={8}
          lg={8}
          style={{
            paddingBottom: "20px",
            position: "relative",
            top: "10px",
          }}
        >
          <ArrowRightAltIcon
            style={{
              color: "#212121",
              position: "relative",
              top: "5px",
            }}
          />
          <Link
            to="/seo-consultation"
            href="https://atlasagency.ca/seo-consultation"
            className={classes.link}
          >
            SEO Consultation
          </Link>
        </Grid>
        <Grid
          item
          xs={11}
          sm={11}
          md={8}
          lg={8}
          style={{
            paddingBottom: "40px",
            position: "relative",
            top: "10px",
          }}
        >
          <ArrowRightAltIcon
            style={{
              color: "#273746",
              position: "relative",
              top: "5px",
            }}
          />
          <Link
            to="/vancouver-seo"
            href="https://atlasagency.ca/vancouver-seo"
            className={classes.link}
          >
            Vancouver SEO
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}
