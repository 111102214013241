import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"

import styles from "../../jss/landing-webdesign.js"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    file(relativePath: { eq: "seo-company-toronto.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)

export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div>
      <div
        className={classNames(classes.section, classes.sectionDark)}
        style={{
          marginBottom: "80px",
        }}
      >
        <div>
          <Grid container justify="center" spacing={5}>
            <Grid item md={5} sm={5} lg={5} xs={10}>
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt="seo-company-toronto"
                fadeIn={false}
                loading="eager"
              />
            </Grid>
            <Grid item md={6} sm={8} lg={4} xs={11}>
              <div>
                <h3 className={classes.title}> Rank Higher on Google </h3>
                <h6 className={classes.description}>SEO Experts Kelowna </h6>
                <p className={classes.description}>
                  Every business has the potential of being in Google’ s top
                  results.At Atlas Agency, we understand your company’ s
                  needs.With thorough market analysis and by implementing modern
                  SEO solutions, we help you rank higher on Google’ s search
                  results.We are a qualified and experienced SEO company in
                  Kelowna that covers every aspect of SEO, from keyword analysis
                  to creating effective SEO strategies.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
