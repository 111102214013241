import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/intro.js"
import Grid from "@material-ui/core/Grid"

import Accordion from "../../components/Accordion/Accordion.jsx"
import { FAQJsonLd } from "gatsby-plugin-next-seo"

const useStyles = makeStyles({
  ...styles,

  paragraph: {
    color: "#212121",
    fontSize: "18px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  mobileTitleFAQ: {
    fontSize: "30px",
    lineHeight: "35px",
    color: "#d0a057",
    fontWeight: "bold",
    textAlign: "center",
  },
})

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div>
      <FAQJsonLd
        questions={[
          {
            question: "What does SEO stand for?",
            answer:
              "SEO stands for Search Engine Optimization. The idea behind SEO is to rank on the Google search engine and to rank highest organically. What we mean by organically is without having to pay for any ads to get your listing first.",
          },
          {
            question: "How long does SEO take?",
            answer:
              "If you are launching a new website it can take up to 6 months to rank high on the Google search console. Each keyword has its own competition, depending on your industry competition it can take less or more time. ",
          },
          {
            question: "What is the best SEO strategy right now?",
            answer:
              "Making sure your website is HTTPS secured and mobile friendly are two extremely important factors when trying to rank for Google’s search engine. ",
          },
          {
            question: "How to do SEO for a website?",
            answer:
              "We provide customers with on-site and off-site optimizations. For on-site, we embed code into your website that helps Google identify what keywords to rank your website for. We also conduct off-site optimizations such as connecting other websites to yours to increase your website’s domain authority. ",
          },
          {
            question: "How do we get started?",
            answer:
              "Contact us at info@atlasagency.ca or (647)-371-0263 to discus your SEO needs. Within one business day, we will provide you a quote and detailed strategy for ranking on Google’s search engine. ",
          },
        ]}
      />
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
        direction="column"
        className={classes.desktopContainerInfo}
      >
        <Grid item lg={12}>
          <h2
            className={classes.title}
            style={{ color: "#273746", textTransform: "uppercase" }}
          >
            Frequently Asked Questions (FAQ)
          </h2>
        </Grid>
        <Grid item xs={12} sm={8} md={8} style={{ paddingBottom: "40px" }}>
          <Accordion
            active={0}
            activeColor="info"
            collapses={[
              {
                title: "What does SEO stand for?",
                content:
                  "SEO stands for Search Engine Optimization. The idea behind SEO is to rank on the Google search engine and to rank highest organically. What we mean by organically is without having to pay for any ads to get your listing first.",
              },
              {
                title: "How long does SEO take?",
                content:
                  "If you are launching a new website it can take up to 6 months to rank high on the Google search console. Each keyword has its own competition, depending on your industry competition it can take less or more time. ",
              },
              {
                title: "What is the best SEO strategy right now?",
                content:
                  "Making sure your website is HTTPS secured and mobile friendly are two extremely important factors when trying to rank for Google’s search engine. ",
              },
              {
                title: "How to do SEO for a website?",
                content:
                  "We provide customers with on-site and off-site optimizations. For on-site, we embed code into your website that helps Google identify what keywords to rank your website for. We also conduct off-site optimizations such as connecting other websites to yours to increase your website’s domain authority. ",
              },
              {
                title: "How do we get started?",
                content:
                  "Contact us at info@atlasagency.ca or (647)-371-0263 to discus your SEO needs. Within one business day, we will provide you a quote and detailed strategy for ranking on Google’s search engine. ",
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}
