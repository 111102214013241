import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"

import styles from "../../jss/intro.js"
import SpellcheckIcon from "@material-ui/icons/Spellcheck"

import CodeIcon from "@material-ui/icons/Code"
import LinkIcon from "@material-ui/icons/Link"
import Contact from "./Contact.jsx"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div
      className={classes.section}
      style={{ paddingBottom: "60px", paddingTop: "20px" }}
    >
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={8} lg={8} align="center">
          <h1 className={classes.title}> SEO Company Kelowna </h1>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={11} align="center">
          <p className={classes.description}>
            Atlas Agency works with experienced and reputable web developers and
            SEO experts.Our primary goal is to improve your online
            presence.Getting your website in Google’ s top results is just a
            small step. At Atlas Agency, we use proven SEO strategies to help
            you stand out amongst competitors and gain more access to your
            target audience. As an experienced SEO company in Kelowna, we offer
            variety of services including:
          </p>
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: 40,
        }}
      >
        <Grid container spacing={5} justify="center">
          <Grid item xs={12} sm={6} md={10} lg={4}>
            <InfoArea
              title="Back Linking"
              description="Google ranks websites based on relevance of citations and keywords. The more citations i.e. links that refer to your website, the higher you will rank on Google. Atlas Agency guarantees results by using white-hat back linking to connect your website to others on the world wide web and strengthen your position in Google’s results. "
              icon={LinkIcon}
              iconColor="info"
              vertical
            />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <InfoArea
              title="Keyword Research"
              description="Quality keyword research is the key to a successful SEO campaign. At Atlas Agency, we focus on every client independently, providing personalized keyword analysis. Depending on your business, we identify which keywords are most searched by your potential customer and ensure you rank highest for each of them."
              icon={SpellcheckIcon}
              iconColor="info"
              vertical
            />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <InfoArea
              title="META Tag Optimization"
              description="Meta tags are short descriptions of a page’s content and are only visible in the HTML codes. When used correctly, meta tags play a large role in your Google rank. Our team identifies crucial keywords during research and later includes them in meta tags. With this, we optimize your web pages and help Google identify and place your business in the top position."
              icon={CodeIcon}
              iconColor="info"
              vertical
            />
          </Grid>{" "}
          <Grid item lg={10} xs={10} sm={6} md={5}>
            <div>
              <iframe
                className={classes.image}
                src="https://www.youtube.com/embed/a3SQc5ILmG8"
                frameBorder="0"
                allow="encrypted-media; gyroscope;"
                allowFullScreen
              />
              
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={12} style={{ position: "relative" ,top: "-40px" }}>
            <Contact />
          </Grid>
      </div>
    </div>
  )
}
